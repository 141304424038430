import Inputmask from 'inputmask'

const maskPatterns = {
    date: {
        mask: 'datetime',
        inputFormat: 'dd/mm/yyyy',
        placeholder: '__/__/____',
    },
}

function initMask(element, type) {
    const { mask, inputFormat, placeholder } = maskPatterns[type]

    if (mask) {
        const inputMask = new Inputmask(mask, {
            showMaskOnHover: false,
            showMaskOnFocus: false,
            inputFormat,
            placeholder,
        })

        inputMask.mask(element)
    }
}

window.initMask = initMask
